@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Light.woff2') format('woff2'),
		url('../assets/fonts/Roboto-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
		url('../assets/fonts/Roboto-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Medium.woff2') format('woff2'),
		url('../assets/fonts/Roboto-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Bold.woff2') format('woff2'),
		url('../assets/fonts/Roboto-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Material Icons';
	src: url('../assets/fonts/MaterialIcons-Regular.woff2') format('woff2'),
		url('../assets/fonts/MaterialIcons-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

// -------- Mat fonts --------

@font-face {
	font-family: 'Turret Road';
	src: url('../assets/fonts/Turret_Road/Turret-Road-Regular.woff');
}

@font-face {
	font-family: 'Vibes';
	src: url('../assets/fonts/Vibes/Vibes-Regular.woff');
}

@font-face {
	font-family: 'American Typewriter';
	src: url('../assets/fonts/American_Typewriter/American-Typewriter-Regular.woff');
}

@font-face {
	font-family: 'Avenir';
	src: url('../assets/fonts/Avenir/Avenir-Regular.woff');
}

@font-face {
	font-family: 'Arial';
	src: url('../assets/fonts/Arial/Arial-Regular.woff');
}

@font-face {
	font-family: 'Berlin Sans';
	src: url('../assets/fonts/Berlin_Sans/Berlin-Sans-Regular.woff');
}

@font-face {
	font-family: 'Brush Script';
	src: url('../assets/fonts/Brush_Script/Brush-Script-Regular.woff');
}

@font-face {
	font-family: 'Chalkboard';
	src: url('../assets/fonts/Chalkboard/Chalkboard-Regular.woff');
}

@font-face {
	font-family: 'Corbel';
	src: url('../assets/fonts/Corbel/Corbel-Regular.woff');
}

@font-face {
	font-family: 'Franklin Gothic';
	src: url('../assets/fonts/Franklin_Gothic/Franklin-Gothic-Regular.woff');
}

@font-face {
	font-family: 'Gill Sans';
	src: url('../assets/fonts/Gill_Sans/Gill-Sans-Regular.woff');
}

@font-face {
	font-family: 'Impact';
	src: url('../assets/fonts/Impact/Impact-Regular.woff');
}

@font-face {
	font-family: 'Lucida Sans';
	src: url('../assets/fonts/Lucida_Sans/Lucida-Sans-Regular.woff');
}

@font-face {
	font-family: 'Marker Felt';
	src: url('../assets/fonts/Marker_Felt/Marker-Felt-Regular.woff');
}

@font-face {
	font-family: 'Papyrus';
	src: url('../assets/fonts/Papyrus/Papyrus-Regular.woff');
}

@font-face {
	font-family: 'Songer';
	src: url('../assets/fonts/Songer/Songer-Regular.woff');
}

@font-face {
	font-family: 'Arvo';
	src: url('../assets/fonts/Arvo/Arvo-Regular.woff');
}

@font-face {
	font-family: 'Asap';
	src: url('../assets/fonts/Asap/Asap-Regular.woff');
}

@font-face {
	font-family: 'Bitter';
	src: url('../assets/fonts/Bitter/Bitter-Regular.woff');
}

@font-face {
	font-family: 'Cairo';
	src: url('../assets/fonts/Cairo/Cairo-Regular.woff');
}

@font-face {
	font-family: 'Cantarell';
	src: url('../assets/fonts/Cantarell/Cantarell-Regular.woff');
}

@font-face {
	font-family: 'Cinzel Decorative';
	src: url('../assets/fonts/Cinzel_Decorative/Cinzel-Decorative-Regular.woff');
}

@font-face {
	font-family: 'Cuprum';
	src: url('../assets/fonts/Cuprum/Cuprum-Regular.woff');
}

@font-face {
	font-family: 'Dosis';
	src: url('../assets/fonts/Dosis/Dosis-Regular.woff');
}

@font-face {
	font-family: 'Droid Serif';
	src: url('../assets/fonts/Droid_Serif/Droid-Serif-Regular.woff');
}

@font-face {
	font-family: 'Exo';
	src: url('../assets/fonts/Exo/Exo-Regular.woff');
}

@font-face {
	font-family: 'Fira Sans';
	src: url('../assets/fonts/Fira_Sans/Fira-Sans-Regular.woff');
}

@font-face {
	font-family: 'Gentium Basic';
	src: url('../assets/fonts/Gentium_Basic/Gentium-Basic-Regular.woff');
}

@font-face {
	font-family: 'Halant';
	src: url('../assets/fonts/Halant/Halant-Regular.woff');
}

@font-face {
	font-family: 'Heebo';
	src: url('../assets/fonts/Heebo/Heebo-Regular.woff');
}

@font-face {
	font-family: 'Istok Web';
	src: url('../assets/fonts/Istok_Web/Istok-Web-Regular.woff');
}

@font-face {
	font-family: 'Josefin Sans';
	src: url('../assets/fonts/Josefin_Sans/Josefin-Sans-Regular.woff');
}

@font-face {
	font-family: 'Jura';
	src: url('../assets/fonts/Jura/Jura-Regular.woff');
}

@font-face {
	font-family: 'Kanit';
	src: url('../assets/fonts/Kanit/Kanit-Regular.woff');
}

@font-face {
	font-family: 'Lato';
	src: url('../assets/fonts/Lato/Lato-Regular.woff');
}

@font-face {
	font-family: 'Libre Baskerville';
	src: url('../assets/fonts/Libre_Baskerville/Libre-Baskerville-Regular.woff');
}

@font-face {
	font-family: 'Lobster Two';
	src: url('../assets/fonts/Lobster_Two/Lobster-Two-Regular.woff');
}

@font-face {
	font-family: 'Maven Pro';
	src: url('../assets/fonts/Maven_Pro/Maven-Pro-Regular.woff');
}

@font-face {
	font-family: 'Neuton';
	src: url('../assets/fonts/Neuton/Neuton-Regular.woff');
}

@font-face {
	font-family: 'Nobile';
	src: url('../assets/fonts/Nobile/Nobile-Regular.woff');
}

@font-face {
	font-family: 'Nunito';
	src: url('../assets/fonts/Nunito/Nunito-Regular.woff');
}

@font-face {
	font-family: 'Open Sans';
	src: url('../assets/fonts/Open_Sans/Open-Sans-Regular.woff');
}

@font-face {
	font-family: 'Oswald';
	src: url('../assets/fonts/Oswald/Oswald-Regular.woff');
}

@font-face {
	font-family: 'Passion One';
	src: url('../assets/fonts/Passion_One/Passion-One-Regular.woff');
}

@font-face {
	font-family: 'PT Sans';
	src: url('../assets/fonts/PT_Sans/PT-Sans-Regular.woff');
}

@font-face {
	font-family: 'PT Serif';
	src: url('../assets/fonts/PT_Serif/PT-Serif-Regular.woff');
}

@font-face {
	font-family: 'Quicksand';
	src: url('../assets/fonts/Quicksand/Quicksand-Regular.woff');
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../assets/fonts/Roboto_Condensed/Roboto-Condensed-Regular.woff');
}

@font-face {
	font-family: 'Roboto Mono';
	src: url('../assets/fonts/Roboto_Mono/Roboto-Mono-Regular.woff');
}

@font-face {
	font-family: 'Roboto Slab';
	src: url('../assets/fonts/Roboto_Slab/Roboto-Slab-Regular.woff');
}

@font-face {
	font-family: 'Signika';
	src: url('../assets/fonts/Signika/Signika-Regular.woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../assets/fonts/Source_Sans_Pro/Source-Sans-Pro-Regular.woff');
}

@font-face {
	font-family: 'Source Serif Pro';
	src: url('../assets/fonts/Source_Serif_Pro/Source-Serif-Pro-Regular.woff');
}

@font-face {
	font-family: 'Teko';
	src: url('../assets/fonts/Teko/Teko-Regular.woff');
}

@font-face {
	font-family: 'Titillium Web';
	src: url('../assets/fonts/Titillium_Web/Titillium-Web-Regular.woff');
}

@font-face {
	font-family: 'Ubuntu';
	src: url('../assets/fonts/Ubuntu/Ubuntu-Regular.woff');
}

@font-face {
	font-family: 'Ubuntu Mono';
	src: url('../assets/fonts/Ubuntu_Mono/Ubuntu-Mono-Regular.woff');
}

@font-face {
	font-family: 'Yanone Kaffeesatz';
	src: url('../assets/fonts/Yanone_Kaffeesatz/Yanone-Kaffeesatz-Regular.woff');
}
