/**
 * Application initial colors
 */

$darkBlue: #0c101b;
$orange: #ffa500;
$black: #0d0d0d;
$white: #ffffff;

$linkHoverColor: $black;
$bodyColor: $black;
$linkColor: $black;
$bodyBg: $white;
