/**
 * Common
 */

.root {
	position: relative;
	min-width: 360px;
}

h1 {
	font-size: 3rem;

	@media (max-width: $deviceSizeSM) {
		font-size: 2.5rem;
	}

	@media (max-width: $deviceSizeXXXS) {
		font-size: 2rem;
	}
}

h2 {
	font-size: 2em;

	@media (max-width: $deviceSizeSM) {
		font-size: 1.5rem;
	}
}

h3 {
	font-size: 1.5rem;

	@media (max-width: $deviceSizeSM) {
		font-size: 1rem;
	}
}

h4 {
	font-size: 1.2rem;

	@media (max-width: $deviceSizeSM) {
		font-size: 1rem;
	}
}

.text-mini {
	font-size: 0.75rem;
}

.text-small {
	font-size: 0.875rem;

	@media (max-width: $deviceSizeSM) {
		font-size: 0.75rem;
	}
}

.text-middle {
	font-size: 1.125rem;

	@media (max-width: $deviceSizeSM) {
		font-size: 1rem;
	}
}

.img-cover {
	max-width: 100%;
	display: block;
	height: auto;
	object-fit: cover;
}

.img-contain {
	max-width: 100%;
	display: block;
	height: auto;
	object-fit: contain;
}

.material-icons {
	font-family: 'Material Icons';
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
	user-select: none;
}

.show-info {
	opacity: 0;
	animation: show 0.4s 0.15s ease both;
}

.modal {
	&__icon-wrapper {
		margin-bottom: 1.5rem;
		text-align: center;

		.font-icon {
			font-size: 44px;
			color: var(--onPrimaryDark);
		}
	}

	&__subtitle {
		margin: 0 auto 1rem;
		color: var(--onPrimaryDark);
		text-align: center;
	}

	&__description {
		margin: 0 auto 1rem;
		color: var(--onPrimary);
		text-align: center;
	}

	&__two-buttons-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
		grid-auto-rows: min-content;
		grid-gap: 1rem;
	}
}

.form {
	&__icon-wrapper {
		margin-bottom: 1.5rem;
		text-align: center;

		.font-icon {
			font-size: 44px;
			color: var(--onPrimaryDark);
		}
	}

	&__subtitle {
		margin: 0 auto 1rem;
		color: var(--onPrimaryDark);
		text-align: center;
	}

	&__two-buttons-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
		grid-auto-rows: min-content;
		grid-gap: 1rem;
	}
}

.structs {
	&__loader-wrapper {
		@include display-flex(row, center, center);
		min-height: 200px;
	}
}
