@import './constants/colors';

/**
 * App animations
 */

// Show

@keyframes show {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// Modal content

@keyframes modal-content {
	to {
		opacity: 1;
		transform: translateY(0px);
	}
}

// Blink

@keyframes blink {
	0% {
		opacity: 0.2;
	}

	20% {
		opacity: 1;
	}

	100% {
		opacity: 0.2;
	}
}

// Dropdown show

@keyframes dropdown-show {
	0% {
		opacity: 0;
		transform: translateY(12px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

// Dropdown scale show

@keyframes dropdown-scale-show {
	from {
		transform: scale(0.75);
		opacity: 0;
	}

	to {
		transform: scale(1);
		opacity: 1;
	}
}

// Modal fades

@keyframes modalFadeDown {
	0% {
		transform: translate3d(0, -30px, 0);
		opacity: 0;
	}

	100% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@keyframes modalFadeUp {
	0% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	100% {
		transform: translate3d(0, -30px, 0);
		opacity: 0;
	}
}

// Keyframes for add and search animated icons

@keyframes plusver {
	0% {
		top: 0;
		opacity: 1;
	}

	10% {
		top: 0;
		opacity: 1;
	}

	20% {
		top: 120%;
		opacity: 1;
	}

	21% {
		top: 120%;
		opacity: 1;
	}

	30% {
		top: -120%;
		opacity: 1;
	}

	40% {
		top: 0;
		opacity: 1;
	}

	100% {
		top: 0;
		opacity: 1;
	}
}

@keyframes plushor {
	0% {
		left: 0;
		opacity: 1;
	}

	10% {
		left: 0;
		opacity: 1;
	}

	25% {
		left: 120%;
		opacity: 1;
	}

	26% {
		left: 120%;
		opacity: 1;
	}

	35% {
		left: -120%;
		opacity: 1;
	}

	45% {
		left: 0;
		opacity: 1;
	}

	100% {
		left: 0;
		opacity: 1;
	}
}

@keyframes gring {
	0% {
		width: 70%;
		height: 70%;
	}

	15% {
		width: 70%;
		height: 70%;
	}

	17.5% {
		width: 60%;
		height: 60%;
	}

	20% {
		width: 80%;
		height: 80%;
	}

	22.5% {
		width: 70%;
		height: 70%;
	}

	100% {
		width: 70%;
		height: 70%;
	}
}

@keyframes gblink {
	0% {
		width: 20%;
		height: 20%;
		transform: rotate(0deg);
	}

	15% {
		width: 20%;
		height: 20%;
		transform: rotate(0deg);
	}

	17.5% {
		width: 10%;
		height: 10%;
		transform: rotate(0deg);
	}

	20% {
		width: 30%;
		height: 30%;
		transform: rotate(0deg);
	}

	22.5% {
		width: 20%;
		height: 20%;
		transform: rotate(0deg);
	}

	40% {
		width: 20%;
		height: 20%;
		transform: rotate(360deg);
	}

	100% {
		width: 20%;
		height: 20%;
		transform: rotate(360deg);
	}
}

@keyframes ghandle {
	0% {
		width: 32%;
		height: 32%;
	}

	15% {
		width: 32%;
		height: 32%;
	}

	17.5% {
		width: 40%;
		height: 40%;
	}

	20% {
		width: 26%;
		height: 26%;
	}

	22.5% {
		width: 32%;
		height: 32%;
	}

	100% {
		width: 32%;
		height: 32%;
	}
}

// Spin animation for fullscreen loader

@keyframes spin {
	0% {
		transform: rotate(0deg);
		filter: hue-rotate(0deg);
		border-radius: 100%;
		box-shadow: 0 0 0 6px $orange;
	}

	50% {
		transform: rotate(180deg);
		filter: hue-rotate(180deg);
		border-radius: 10%;
		box-shadow: 0 0 0 9px $orange;
	}

	100% {
		transform: rotate(360deg);
		filter: hue-rotate(360deg);
		border-radius: 100%;
		box-shadow: 0 0 0 6px $orange;
	}
}
