@import '../../../../styles/constants/index.scss';
@import '../../../../styles/_mixins.scss';

.crop-image-filters {
	width: 100%;

	&__cells-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
		grid-template-rows: 30px;
		width: 100%;
		grid-gap: 2px;
	}
}
