/**
* Mixins
*/

@mixin text-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

@mixin display-flex(
	$flex-direction: row,
	$align-horizontal: start,
	$align-vertical: stretch
) {
	display: flex;
	flex-direction: $flex-direction;
	justify-content: $align-horizontal;
	align-items: $align-vertical;
}
