/**
 * Application styles config
 */

// -------- UI elements parameters --------

$borderRadius: 3px;
$borderRadiusMD: 4px;

// -------- Devices sizes (for min width) --------

// The Standard Bootstrap 4.x Breakpoints

$deviceSizeLS: 1200px;
$deviceSizeMD: 992px;
$deviceSizeSM: 768px;
$deviceSizeXS: 576px;

// The Custom App Breakpoints

$deviceSizeXLG: 1366px; // iPad Pro
$deviceSizeXMD: 1024px;
$deviceSizeXXS: 460px;
$deviceSizeXXXS: 375px;

// -------- Fonts --------

// Family group

$fontFamilyBase: 'Roboto';

// Weight group

$fontWeightMedium: 500;
$fontWeightThin: 300;
$fontWeightBase: 400;
$fontWeightBold: 700;

// Line height group

$lineHeightBase: 1.5;

// Size group

$fontSizeBase: 16px;

// -------- Animation durations --------

$animTimeDefault: 0.25s;
$animTimeSlow: 0.3s;
$animTimeFast: 0.2s;
